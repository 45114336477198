<template>
    <section class="content">
        <div class="flex justify-content-center">
            <div class="w-100 card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, npaid: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-pink elevation-1 p-1">
                                    <i class="fi fi-rr-coins d-flex"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">Belum Dibayar</h5>
                                    <h5 class="info-box-number">{{ belum_rekon }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, rts: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-purple elevation-1">
                                    <i class="fi fi-rr-rotate-left d-flex"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">RTS</h5>
                                    <h5 class="info-box-number">{{ rts }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, delok: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-cyan elevation-1">
                                    <i class="fi fi-rr-time-check d-flex"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">Terkirim</h5>
                                    <h5 class="info-box-number">{{ terkirim }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, rpick: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-yellow elevation-1">
                                    <i class="fi fi-rr-hourglass-end d-flex text-light"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">Pending</h5>
                                    <h5 class="info-box-number">{{ pending }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, canc: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-green elevation-1">
                                    <i class="fi fi-rr-time-delete d-flex"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">Cancel</h5>
                                    <h5 class="info-box-number">{{ cancel }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <router-link class="info-box text-dark" :to="{
                                name: 'Arsip Riwayat', 
                                params: { filter: { gid: $route.params.id, recon: 1, dt1: dt, dt2: dt } }
                            }">
                                <span class="info-box-icon bg-teal elevation-1">
                                    <i class="fi fi-rr-comments-question d-flex"></i>
                                </span>
                                <div class="info-box-content">
                                    <h5 class="info-box-text">Rekonsiliasi</h5>
                                    <h5 class="info-box-number">{{ rekon }}</h5>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-lg btn-block btn-primary" v-on:click="goBack"><i class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import 'jquery-ui';
import moment from "moment";

export default {
    name: "DetailOrderGroup",
    data() {
        return {
            code: 0,
            belum_rekon: 0,
            rts: 0,
            terkirim: 0,
            pending: 0,
            cancel: 0,
            rekon: 0,
            dt: moment().format("YYYY-MM-DD"),
        };
    },
    created: function () {
    },
    methods: {
        goBack() {
            window.close();
        }
    },
    mounted() {
        authFetch("/order/arsip_group_id/" + this.$route.params.id, {
            method: "GET",
        })
        .then((res) => {
            return res.json();
        })
        .then((js) => {
            this.code = js.code ? js.code : 0;
            this.belum_rekon = js.belum_rekon ? js.belum_rekon : 0;
            this.rts = js.rts ? js.rts : 0;
            this.terkirim = js.terkirim ? js.terkirim : 0;
            this.pending = js.pending ? js.pending : 0;
            this.cancel = js.cancel ? js.cancel : 0;
            this.rekon = js.rekon ? js.rekon : 0;
            this.dt = js.pickup_time ? js.pickup_time : moment().format("YYYY-MM-DD");
        });
    },
};
</script>